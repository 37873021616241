<template>
  <h4>{{ $t("soon") }}</h4>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class InvoicesDebtReceived extends Vue {}
</script>
